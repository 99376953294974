import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddShoppingCartIcon, Csku } from "@commonsku/styles";

import {
  createEditItem,
  createUpdateBreakdown,
} from '../../../actions/shop';
import { createDeleteTemp } from "../../../actions/temp";
import { TemplateIconButton } from "../helpers";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { selectCartItemQuantity } from "../../../selectors";

const CONTAINER_STYLE = { paddingRight: 8 };
const MINIMUM_QUANTITY_STYLE = { color: 'var(--color-errors-main)', paddingBottom: '16px' };
const HOVER_STYLE = {
  background: 'var(--color-neutrals-50) !important',
  outline: 'var(--color-neutrals-50) !important',
  borderColor: 'var(--color-neutrals-50) !important',
  color: 'var(--color-neutrals-70) !important',
};
const SKU_DISABLED_STYLE = { color: '#BF3F69', fontWeight: 'bold' };

export default function AddItemButton(props) {
  const {
    item,
    selectedSizeOptions,
    cart,
    disabled = false,
    showItemCount = true,

    onClickCart,
    onAddItem,
  } = props;
  const {
    baseUrl,
    entityIsFree,
    entityAggregate,
    entityBuyInventory,
    entityForceMinimumQty,
    entityId
  } = usePublicViewEntityContext();
  const {
    templateColor: template_color,
    productPageStyle
  } = usePublicViewTemplateDataContext();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const itemQty = useMemo(
    () => cart[item.item_id]?.reduce((t, b) => t + parseFloat(b.quantity), 0) ?? 0,
    [cart, item.item_id]
  );

  const shopItemQty = useSelector(s => selectCartItemQuantity(s, item));

  const inventoryOk = useSelector(
    state => Object.values(state.entities.inventory_items ?? {}).filter(
      ii => ii.source_item_id === item.item_id 
    ).length === 0 || !!entityBuyInventory
  );

  const baseMinimumQuantity = useSelector(
    state => Object.values(state.entities.items ?? {}).filter(
      i => i.option_item_id === item.item_id && i.parent_type === 'PRODUCT' && 0 === +i.hidden
    ).map(
      i => +i.total_units
    ).sort((a, b) => {
      return a - b;
    })[0] ?? 0
  );

  const isMinimumEnforced = (+entityAggregate === 1 && +entityForceMinimumQty === 1) || +entityAggregate === 0;
  const minQuantity = inventoryOk && isMinimumEnforced ? baseMinimumQuantity : 1;

  const fullWidth = selectedSizeOptions.length >= 2;
  const isDisabled = disabled || +itemQty === 0;

  const tempCartItem = cart[item.item_id];
  const handleAdd = useCallback(
    e => {
      e.stopPropagation();
      if (!cart[item.item_id]) {
        return;
      }
      dispatch(createEditItem(entityId, item.item_id, entityIsFree, false, ));
      (Array.isArray(cart[item.item_id]) ? cart[item.item_id] : [cart[item.item_id]]).filter(
        i => i.to_be_added !== false
      ).forEach(
        i => dispatch(
          createUpdateBreakdown(
            i.item_id,
            i.color_id,
            i.size_id,
            i.product_sku_id,
            i.quantity,
            entityIsFree,
            entityId
          )
        )
      );
      if (+entityIsFree === 1) {
        onAddItem && onAddItem();
        navigate(`${baseUrl}/checkout` + (!!entityBuyInventory ? '?buy_inventory=true' : ''));
        return;
      } else if (productPageStyle !== 'POPUP') {
        onClickCart && onClickCart();
      }
      onAddItem && onAddItem();
    },
    [cart, item.item_id, dispatch, entityIsFree, entityId, productPageStyle, onAddItem, navigate, baseUrl, entityBuyInventory, onClickCart]
  );

  const handleRemove = useCallback(
    e => {
      e.stopPropagation();
      dispatch(createDeleteTemp(item_id));
    },
    [dispatch, item.item_id]
  );

  const isAdd = showItemCount || !itemQty;
  const handleClick = isDisabled ?  null : (isAdd ? handleAdd : handleRemove);

  const isCountShown = showItemCount && shopItemQty > 0;
  const countStyle = useMemo(
    () => ({
      background: 'transparent',
      color: template_color,
      padding: '0.5rem 0',
      borderRadius: '1rem',
      fontWeight: 'bold',
    }),
    [template_color]
  );

  const isMinQuantityInCart = minQuantity == null || shopItemQty >= minQuantity;

  const hoverStyle = useMemo(
    () => isDisabled ? HOVER_STYLE : {},
    [isDisabled]
  );

  const buttonStyle = useMemo(
    () => ({
      ...(fullWidth ? { width: '100%' } : {}),
      ':hover': hoverStyle,
      ':focus': hoverStyle
    }),
    [hoverStyle, fullWidth]
  );

  const visibleOptions = item.options.filter(
    o => 0 === +o.hidden && 0 !== +o.total_units
  );
  if (visibleOptions.length === 0 && +entityAggregate === 0) {
    return null;
  }

  const isSkuDisabled = entityBuyInventory && item.item_skus.length === 0 && (
    item.item_colors.length < 1 || item.item_sizes.length < 1
  );
  if (isSkuDisabled) {
    return (
      <p style={SKU_DISABLED_STYLE}>
        Unable to buy inventory items without at least one size and color.
      </p>
    );
  }

  return (
    <div style={CONTAINER_STYLE}>
      {isCountShown && (
        <div style={countStyle}>
          You have {shopItemQty} of this item in your cart
        </div>
      )}
      {!isMinQuantityInCart && shopItemQty > 0 && (
        <div style={MINIMUM_QUANTITY_STYLE}>
          Add at least {minQuantity - shopItemQty} more to match the minimum quantity of {minQuantity}
        </div>
      )}
      <Csku
        as={TemplateIconButton}
        template_color={template_color}
        disabled={isDisabled}
        size="huge"
        Icon={AddShoppingCartIcon}
        onClick={handleClick}
        style={buttonStyle}
      >
        <span>{+entityIsFree === 1 ? 'Select' : 'Add to Cart'}</span>
      </Csku>
    </div>
  );
}
