import React, { useEffect } from "react";

import Header from "./Header";
import Footer from "./Footer";

import { oauth } from "../../utils";
import { CompletedCheckmarkIcon } from "@commonsku/styles";
import { usePublicViewEntityContext } from "../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../context/PublicViewTemplateDataProvider";
import { cleanCartFromLocalStorage } from "../../utils/cartLocalStorage";

const Receipt = () => {
  const { entityAggregate, entityBuyInventory, checkoutMessage, entityId } =
    usePublicViewEntityContext();
  const { templateColor: template_color } = usePublicViewTemplateDataContext();

  useEffect(() => {
    let url = new URL(window.location.href);
    let session_id = url.searchParams.get("session_id");
    let pay_order_id = url.searchParams.get("pay_order_id");
    let no_fulfillment = url.searchParams.get("no_fulfillment");

    if (session_id && pay_order_id) {
      let data = {
        action: "fulfill-payment",
        session_id,
        parent_id: pay_order_id,
        parent_type: +entityAggregate === 0 ? "SALES ORDER" : "PARTIAL",
      };
      if (no_fulfillment) {
        data.no_fulfillment = true;
      }
      oauth("POST", "stripe", data);
    }
    cleanCartFromLocalStorage(entityId);
  }, [entityAggregate, entityId]);

  return (
    <div
      className="shop"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: "#fff",
      }}
    >
      <Header isEditable={false} buy_inventory={entityBuyInventory} />
      <div className="row">
        <div
          className="small-12 large-6 small-centered columns thanks"
          style={{ textAlign: "center", fontSize: "22px", marginBottom: 50 }}
        >
          <CompletedCheckmarkIcon
            color={template_color}
            width={120}
            height={120}
          />
          {!!entityBuyInventory ? (
            <h3 style={{ textAlign: "center", color: template_color }}>
              Your inventory order has been received.
            </h3>
          ) : checkoutMessage.replace(/<(?:.|\n)*?>/gm, "") !== "" ? (
            <p
              style={{ textAlign: "center", color: template_color }}
              dangerouslySetInnerHTML={{ __html: checkoutMessage }}
            />
          ) : (
            <h3 style={{ textAlign: "center", color: template_color }}>
              Thank you for your order!
            </h3>
          )}
        </div>
      </div>
      <Footer
        isEditable={false}
        centerAligned
        style={{
          borderTop: "none",
          padding: 0,
          marginTop: 0,
        }}
      />
    </div>
  );
};

export default Receipt;
