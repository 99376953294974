import { get } from "lodash";
import ReactDOM from "react-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Col, Csku, HelpIcon, Overlay, Row } from "@commonsku/styles";

import { createUpdatePublicViewTemplateData } from "../../../actions/shop";
import { createDeleteTemp } from "../../../actions/temp";
import { createIntroPopup } from "../../../actions/popup";

import { getFullCart } from "../../../selectors";
import { setFilter } from "../../../redux/gallery";
import { getImageSrc } from "../../../utils";
import {
  CartIconLink,
  getShopHeaderTitle,
  hideShopIntro,
} from "../helpers";
import { Medium } from "../../ScreenSize";
import { createUploadFile } from "../../../actions/file";
import ReactTooltip from "../../helpers/ReactTooltip";
import { rebuildTooltip } from "../../helpers";
import ShopTitle from './ShopTitle';
import IntroPopup from "../IntroPopup";
import SmallNav from "./SmallNav";
import { usePublicView } from "../../../context/PublicViewProvider";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { useNavigate } from "react-router";

function getStateFromProps({ title, template_data }) {
  const { headerTitle, headerTitleType } = getShopHeaderTitle(template_data, title);
  return { title: headerTitle, title_type: headerTitleType };
}

function IntroPopupPortal({
  isEditable,
  shopIntroPopup,
  onClose,
}) {
  if (isEditable || !shopIntroPopup) {
    return null;
  }
  return ReactDOM.createPortal(
    <IntroPopup onClose={onClose} />,
    document.getElementById('root')
  );
}

function PreviewShopTitle({
  title,
  title_type,
  editing,
  style={},
}) {
  const { entityBuyInventory } = usePublicViewEntityContext();
  if ('text' === title_type) {
    return (
      <React.Fragment>
        {title}{!!entityBuyInventory && <p style={{ margin: 0, fontSize: 'small', ...style }}>(Buy Inventory)</p>}
      </React.Fragment>
    );
  }
  return (
    <img src={title} alt="" style={{ marginBottom: 'title' === editing ? 24 : 8, }} />
  );
}

function MainMenu({
  viewCart,
  onClickIntro,
  onClickHome,
  onClickAllProducts,
  onChangeFilter,
}) {
  const {
    isShop,
    entityBuyInventory,
  } = usePublicViewEntityContext();
  const {
    templateColor: template_color,
  } = usePublicViewTemplateDataContext();

  const totalQuantity = ((viewCart || {}).items || []).reduce((acc, v) => acc + parseFloat(v.quantity), 0);

  return (
    <div id="main-menu" style={{ height: '100%', left: 160, marginLeft: 0 }}>
      <Row style={{ height: '100%', width: '100%' }}>
        <Col xs={8} md={10} style={{ alignSelf: 'center', textAlign: 'center' }}>
          <a id="shop_home_link" style={{ color: !!entityBuyInventory ? 'white' : template_color }}
            onClick={(e) => { onClickHome(e); onChangeFilter(''); }}
          >Home</a>
          <a id="shop_home_link" style={{ color: !!entityBuyInventory ? 'white' : template_color }}
            onClick={(e) => { onClickAllProducts(e); onChangeFilter(''); }}
          >All Products</a>
        </Col>
        <Col xs={4} md={2} style={{ float: 'right' }}>
          {isShop && <CartIconLink totalQuantity={totalQuantity} />}
          <a onClick={onClickIntro}
            id="shop_help_link"
            style={{
              float: 'right',
              height: '100%',
              marginRight: 0,
            }}
          >
            <HelpIcon style={{ marginRight: 0, marginTop: 3 }} color={template_color} height={'100%'} width={'42px'} />
          </a>
        </Col>
      </Row>
    </div>
  );
}

function Small({
  viewCart,
  menuOpen,
  title,
  title_type,
  editing,
  showBackButton = false,
  onChangeFilter,
  onClickBackButton,

  onClickHome,
  onClickAllProducts,
  onClickIntro,
  handleClickMenu,
  handleClickTitle,
}) {
  const { filter } = usePublicView();
  const { entityOrder: gallery, } = usePublicViewEntityContext();
  const { getShopCategoryWithImages } = usePublicViewTemplateDataContext();
  const items = get(gallery, ['items']) || [];
  const totalQuantity = ((viewCart || {}).items || []).reduce((acc, v) => acc + parseFloat(v.quantity), 0);
  const categories = getShopCategoryWithImages(items);

  return (
    <SmallNav
      filter={filter}
      menuOpen={menuOpen}
      categories={categories}
      totalQuantity={totalQuantity}
      editing={editing}
      title={title}
      title_type={title_type}
      onClickHome={onClickHome}
      onClickAllProducts={onClickAllProducts}
      onClickIntro={onClickIntro}
      onChangeFilter={onChangeFilter}
      onClickBackButton={onClickBackButton}
      showBackButton={showBackButton}
      handleClickMenu={handleClickMenu}
      handleClickTitle={handleClickTitle}
    />
  );
}

const Header = ({
  viewCart,
  first_view,
  hideIntro,
  isEditable,
  showBackButton,

  onFirstView,
  onChangeFilter,
  onClickBackButton,
}) => {
  const { filter, showCart } = usePublicView();
  const {
    isShop,
    baseUrl,
    baseEntityUrl,
    title,
    introduction,
    entityId,
    entityType,
    entityTemplateId,
    contactEmail,
    contactName,
    entityBuyInventory,
  } = usePublicViewEntityContext();
  const {
    templateData,
    template_data,
    templateColor,
  } = usePublicViewTemplateDataContext();

  const dispatch = useDispatch();
  const isMounted = useRef(null);
  const navigate = useNavigate();

  const [state, setState] = useState({
    editing: false,
    menuOpen: false,
    shopIntroPopup: false,
    title: '',
    title_type: '',
    ...getStateFromProps({ title, template_data }),
  });

  const onUpdateTemplateData = (key, value) => dispatch(
    createUpdatePublicViewTemplateData(
      entityId,
      entityType,
      entityTemplateId,
      key,
      value
    )
  );

  const onUseImage = files => dispatch(createUploadFile(entityId, entityType, files[0])).then(
    action => {
      dispatch(
        createUpdatePublicViewTemplateData(
          entityId,
          entityType,
          entityTemplateId,
          'header-title',
          getImageSrc(action.payload.data, 'original')
        )
      );
      return dispatch(
        createUpdatePublicViewTemplateData(
          entityId,
          entityType,
          entityTemplateId,
          'header-title-type',
          'image'
        )
      );
    }
  );

  const onFieldChange = useCallback(
    (field, value) => setState(s => ({ ...s, [field]: value })),
    []
  );

  const onClickHome = useCallback(() => {
    !isEditable && navigate(baseUrl + (!!entityBuyInventory ? '?buy_inventory=true' : ''));
  }, [isEditable, baseUrl, entityBuyInventory, navigate]);

  const onClickAllProducts = useCallback(() => {
    !isEditable && navigate(baseEntityUrl + (!!entityBuyInventory ? '?buy_inventory=true': ''));
  }, [isEditable, baseEntityUrl, entityBuyInventory, navigate]);

  const onClickHelp = useCallback(() => {
    dispatch(createIntroPopup(
      introduction,
      { contact_name: contactName, contact_email: contactEmail },
      templateColor,
      entityBuyInventory,
      entityId,
      isShop,
    ));
  }, [dispatch, introduction, contactName, contactEmail, templateColor, entityBuyInventory, entityId, isShop]);

  const onClickIntro = useCallback(() => {
    const helpScreenStyle = templateData.help_screen_style || 'TEXT_ONLY';
    if (helpScreenStyle === 'TEXT_ONLY') {
      onClickHelp();
    } else {
      setState(s => ({ ...s, shopIntroPopup: true }));
    }
  }, [templateData.help_screen_style, onClickHelp]);

  const handleClickMenu = useCallback((e) => {
    e.preventDefault();
    setState((s) => ({ ...s, menuOpen: !s.menuOpen }));
  }, []);

  const handleClickTitle = useCallback((e) => {
    e.preventDefault();
    if (isEditable) {
      e.stopPropagation();
      setState(s => ({ ...s, editing: 'title' }));
    }
  }, [isEditable]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      const showHelpScreen = +(templateData.help_screen_visible ?? '1') === 1;
      if (first_view &&
        introduction &&
        introduction.replace(/<(?:.|\n)*?>/gm, '') !== '' &&
        showHelpScreen &&
        !window.location.href.match(/receipt/g)
      ) {
        if (!hideShopIntro() && !hideIntro) {
          onClickIntro();
        }
        onFirstView();
      }
      rebuildTooltip();
    }
  }, [templateData, introduction, first_view, hideIntro, onFirstView, onClickIntro]);

  useEffect(() => {
    const newState = getStateFromProps({ template_data, title });
    setState(s => ({ ...s, ...newState }));
    rebuildTooltip();
  }, [template_data, title]);

  const headerStyle = {
    width: '100%',
    zIndex: 99,
  };
  if (!!entityBuyInventory) {
    headerStyle.backgroundColor = '#00D374';
    headerStyle.color = 'white';
  }
  if (showCart) {
    headerStyle.borderBottom = '2px solid rgb(42 56 63 / 53%)';
  }
  return (
    <Csku forceStyles
      className="title-bar sticky"
      style={headerStyle}
      sx={{
        xs: { position: 'fixed', top: 0, },
        md: { position: isEditable ? 'static' : 'relative' }
      }}
    >
      <ReactTooltip id="shop-title-tooltip" />
      <IntroPopupPortal
        isEditable={isEditable}
        shopIntroPopup={state.shopIntroPopup}
        onClose={() => onFieldChange('shopIntroPopup', false)}
      />
      <Medium>
        <ShopTitle
          editing={state.editing}
          title={state.title}
          title_type={state.title_type}
          isEditable={isEditable}
          onDropImage={files => {
            onFieldChange('editing', false);
            onUseImage(files);
          }}
          onChangeTitle={title => onFieldChange('title', title)}
          onBlurTitle={e => {
            onFieldChange('editing', false);
            onUpdateTemplateData('header-title', state.title);
            onUpdateTemplateData('header-title-type', 'text');
          }}
          handleClickTitle={handleClickTitle}
          style={{ width: '38%' }}
          titleClassName={'new-shop-title'}
          PreviewShopTitle={<PreviewShopTitle
            editing={state.editing}
            title={state.title}
            title_type={state.title_type}
          />}
        />
        <MainMenu
          viewCart={viewCart}
          onClickIntro={onClickIntro}
          onClickHome={onClickHome}
          onChangeFilter={onChangeFilter}
          onClickAllProducts={onClickAllProducts}
        />
        {showCart && <Overlay />}
      </Medium>

      <Small
        viewCart={viewCart}
        menuOpen={state.menuOpen}
        title={state.title}
        title_type={state.title_type}
        editing={state.editing}
        showBackButton={showBackButton}
        onChangeFilter={onChangeFilter}
        onClickBackButton={onClickBackButton}
        onClickHome={onClickHome}
        onClickAllProducts={onClickAllProducts}
        onClickIntro={onClickIntro}
        handleClickMenu={handleClickMenu}
        handleClickTitle={handleClickTitle}
      />
    </Csku>
  );
};

const mapStateToProps = (state, ownProps) => ({
  first_view: state.temp.first_view,
  viewCart: getFullCart(state, ownProps),
  filter: (state.gallery || {}).filter,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onFirstView: () => !ownProps.isEditable && dispatch(createDeleteTemp('first_view')),
  onChangeFilter: (filter) => dispatch(setFilter(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
