import React from "react";
import { useSelector } from "react-redux";
import { getCompanyData, getCartItem, getGalleryOrderByParent } from "../../../selectors";
import ItemInfo from "../../gallery/v2/ItemInfo";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

const DEFAULT_STYLE = {};

export default function GalleryItemInfo(props) {
  const {
    item,
    cart,
    params,
    loading,

    onClickCart,
    onEditCartItem,
    onCancelEditCartItem,
    onDeleteCartItem,
    onSaveCartItem,
    onUpdateBreakdown,
    onAddItem,

    style=DEFAULT_STYLE,
    isPreviewing = false,
    itemNameFont=""
  } = props;
  const {
    isShop,
    entityId,
    entityType,
    entityIsFree,
    entityAggregate,
    entityBuyInventory,
    entityShowInventory,
    entityShowProductWarnings,
    entityForceMinimumQty,
    entityClientId,
    entityTenantId,
  } = usePublicViewEntityContext();
  const { templateColor } = usePublicViewTemplateDataContext();

  const default_margin = useSelector(state => getCompanyData(state).default_margin || 40);
  const order = useSelector(state => getGalleryOrderByParent(state, { parent_type: entityType, parent_id: entityId }));
  const shopCartItem = useSelector(s => getCartItem(s, { item_id: item.item_id, buy_inventory: entityBuyInventory }));
  const editingCartItemId = useSelector(state => state.temp.editingCartItemId);

  const hidePricing = +(order?.hide_pricing ?? 0) === 1;

  return (
    <ItemInfo
      item={item}
      item_id={item.item_id}
      currency_symbol={order.currency_symbol}
      showNet={false}
      showVendorInfo={false}
      showProductWarnings={1 == entityShowProductWarnings}
      aggregate={entityAggregate}
      default_margin={default_margin}
      supplier_id={order.supplier_id}
      style={style}
      loading={loading}
      template_color={templateColor}
      buyInventory={!!entityBuyInventory}
      showInventory={!!entityShowInventory || !!entityBuyInventory}
      is_shop_free={entityIsFree}
      buy_inventory={entityBuyInventory}
      show_inventory={entityShowInventory}
      force_minimum_qty={entityForceMinimumQty}
      hidePricing={hidePricing}
      editingCartItemId={editingCartItemId}
      client_id={entityClientId}
      tenant_id={entityTenantId}
      isEditing={true}
      cart={cart}
      shopCartItem={shopCartItem}
      params={params}
      isInCart={false}
      onClickCart={onClickCart}
      onEditCartItem={onEditCartItem}
      onCancelEditCartItem={onCancelEditCartItem}
      onDeleteCartItem={onDeleteCartItem}
      onSaveCartItem={onSaveCartItem}
      onUpdateBreakdown={onUpdateBreakdown}
      onAddItem={onAddItem}
      isPreviewing={isPreviewing}
      itemNameFont={itemNameFont}
      showAddToCartButton={isShop}
      showCartItem={isShop}
      showAddCommentsButton={entityType === 'ORDER'}
    />
  );
}
