import { find, forEach, get, isEqual, omit, pick, sumBy } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Text,
  Input,
  Csku,
  fontFamilies,
  LabeledCheckbox,
  Textarea,
} from "@commonsku/styles";

import { formatMoney, oauth, toneColor, validateEmail } from "../../../utils";
import { getContactName } from "../../contact/utils";
import {
  getAddressCityAndState,
  getAddressPostalCodeAndCountry,
} from "../../address/utils";
import {
  createCardConnectPopup,
  createErrorAlertPopup,
  createSpinnerPopup,
} from "../../../actions/popup";
import {
  createCheckout,
  createCloseCart,
  createDeleteCartItem,
  createEditCartItem,
  createEditCheckout,
} from "../../../actions/shop";
import { getAddress, getContact, getFullCart } from "../../../selectors";
import {
  getAddressDropdown,
  getContactDropdown,
} from "../../../selectors/dropdowns";

import {
  getShopType,
  SHOP_TYPES,
  CHECKOUT_PANELS as PANELS,
  CHECKOUT_PANELS_ORDER as PANELS_ORDER,
  CheckoutPanel,
} from "../helpers";

import CheckoutCartItems from "./CheckoutCartItems";
import CheckoutButtons from "./CheckoutButtons";
import EnterCouponSection from "./EnterCouponSection";
import OrderTotal from "./OrderTotal";
import EnterAddress from "./EnterAddress";
import MetadataList from "./MetadataList";
import EnterContact from "./EnterContact";
import SelectContact from "./SelectContact";
import SelectAddress from "./SelectAddress";

import Address from "../../Address";
import { isAvalaraShop } from "../../../helpers/order";
import Tax from "../../../models/Tax";
import { withRouter } from "../../helpers";
import { publicViewContext } from "../../../context/PublicViewProvider";
import { checkoutCartChecker } from "../../../utils/cartLocalStorage";
import { getShopOptions } from '../../../helpers/shop';

const defaultPanelState = {
  showCheckmark: false,
  showEditBtn: false,
  showPreview: false,
  showErrors: false,
  opened: false,
};

class Checkout extends Component {
  static contextType = publicViewContext;
  /**
   * @type {import('../../../context/PublicViewProvider').TPublicViewContext}
   */
  context;

  constructor(props) {
    super(props);

    const aggregate =
      this.context?.publicViewEntity?.entityAggregate || props.aggregate;
    const clientId =
      this.context?.publicViewEntity?.entityClientId || props.clientId;
    const contact = this.context?.publicViewEntity?.contact || props.contact;
    const hasClient = clientId || contact?.company_id;
    const prefill = contact && contact.contact_first_name;
    const entityType =
      this.context?.publicViewEntity?.entityType || props.entityType;

    const billingAddressType =
      this.context?.publicViewEntity?.entity?.billing_address_type ||
      props.billingAddressType;
    const defaultPanel = !(!!clientId || "TYPED" !== billingAddressType)
      ? PANELS.COMPANY_NAME
      : getShopType({ aggregate, client_id: clientId }) ===
        SHOP_TYPES.COMPANY_SHOP
      ? PANELS.BILLING_INFO
      : PANELS.CUSTOMER_INFO;

    this.state = {
      focus: null,
      sameAsBilling: false,

      billing_address__validated: false,
      shipping_address__validated: false,
      billing_address: {
        address_company: contact?.company_name ?? "",
        address_line_1: "",
        address_line_2: "",
        address_city: "",
        address_state: "",
        address_postal: "",
        address_country: "",
      },
      shipping_address: {
        address_company: contact?.company_name ?? "",
        address_line_1: "",
        address_line_2: "",
        address_city: "",
        address_state: "",
        address_postal: "",
        address_country: "",
      },
      billing_contact: {
        contact_name: prefill
          ? `${contact?.contact_first_name} ${contact?.contact_last_name}`
          : "",
        contact_email: contact?.contact_email ?? "",
      },
      shipping_contact: {
        contact_name: prefill
          ? `${contact?.contact_first_name} ${contact?.contact_last_name}`
          : "",
        contact_email: contact?.contact_email ?? "",
      },
      errors: {},
      comment: "",
      shipping_cost: 0,
      shipping_range_id: null,
      order_contact: {
        contact_name: "",
        contact_email: "",
      },
      order_metadata: {},
      cart: props.cart,
      orderTotal: props.orderTotal,
      code: "",
      show_coupon_apply: false,
      applied_discount: null,
      customer_po: "",
      avalara_summary: false,

      openPanel: defaultPanel,
      PANEL_COMPANY_NAME: defaultPanelState,
      PANEL_SHIPPING_INFO: defaultPanelState,
      PANEL_BILLING_INFO: defaultPanelState,
      PANEL_CUSTOMER_INFO: defaultPanelState,
      PANEL_ADDITIONAl_INFO: defaultPanelState,
      PANEL_COMMENTS: defaultPanelState,
      billing_address_id:
        hasClient &&
        props.addresses.filter((a) => "BILLING" === a.address_type)[0]
          ? props.billingAddressType === "TYPED"
            ? "ADD"
            : ""
          : "ADD",
      shipping_address_id:
        hasClient &&
        props.addresses.filter((a) => "SHIPPING" === a.address_type)[0]
          ? props.shippingAddressType === "TYPED"
            ? "ADD"
            : ""
          : "ADD",
      billing_contact_id:
        hasClient &&
        props.contacts.filter(
          (c) =>
            [entityType, `${entityType}-BILLING`].indexOf(c.company_type) !== -1
        )[0]
          ? props.billingContactType === "TYPED"
            ? "ADD"
            : ""
          : "ADD",
      shipping_contact_id:
        hasClient &&
        props.contacts.filter(
          (c) =>
            [entityType, `${entityType}-SHIPPING`].indexOf(c.company_type) !==
            -1
        )[0]
          ? props.shippingContactType === "TYPED"
            ? "ADD"
            : ""
          : "ADD",
      ...props.checkout,
    };

    [
      "handleToggleSameAsBilling",
      "handleSubmitOrder",
      "handleChangeMetaData",
      "handleRecalculateShippingCost",

      "onCloseCart",
      "toggleOpenPanel",
      "checkCoupon",
      "onFocus",
    ].forEach((key) => {
      this[key] = this[key].bind(this);
    });
  }

  componentDidMount() {
    const shop_id = this.props.entityId;
    const localCartData = checkoutCartChecker(shop_id);
    // If there is nothing in cart, we should go back to cart
    if (!localCartData) {
      this.onOpenCart();
    }

    this.handleRecalculateShippingCost();

    const { onEditCheckout, checkout } = this.props;
    if (Object.values(checkout || {}).length === 0) {
      const data = omit(this.state, [
        "cart",
        "shop",
        "orderTotal",
        "focus",
        "avalara_summary",
        "code",
        "applied_discount",
      ]);
      onEditCheckout(data);
    }
    const discount = pick(this.state, ["applied_discount"]);
    if (discount && discount.applied_discount) {
      this.checkCoupon();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onEditCheckout } = this.props;
    const prevData = omit(prevState, [
      "cart",
      "shop",
      "orderTotal",
      "focus",
      "avalara_summary",
      "code",
      "applied_discount",
    ]);
    const data = omit(this.state, [
      "cart",
      "shop",
      "orderTotal",
      "focus",
      "avalara_summary",
      "code",
      "applied_discount",
    ]);
    if (!isEqual(prevData, data)) {
      onEditCheckout(data);
    }
    const preDiscount = pick(prevState, ["orderTotal", "applied_discount"]);
    const dataDiscount = pick(this.state, ["orderTotal", "applied_discount"]);
    if (preDiscount.applied_discount && !isEqual(preDiscount, dataDiscount)) {
      this.checkCoupon();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(
      { cart: nextProps.cart, orderTotal: nextProps.orderTotal },
      function () {
        this.handleRecalculateShippingCost();
      }
    );
  }

  onCloseCart() {
    const { dispatch } = this.props;
    const entityId =
      this.context?.publicViewEntity?.entityId || this.props.entityId;
    return dispatch(createCloseCart(entityId));
  }

  onOpenCart() {
    // don't change until not being used in constructor
    const { navigate } = this.props;
    const { entityLocation = "" } = this.context?.publicViewEntity || {};
    Promise.resolve(this.onCloseCart()).then(() => {
      if (entityLocation) {
        navigate(entityLocation);
      }
    });
  }

  handleRecalculateShippingCost() {
    const {
      publicViewEntity: { entity, entityBuyInventory },
    } = this.context;
    const { orderTotal } = this.state;

    if (!!entityBuyInventory) {
      return;
    }

    let selected_shipping_range =
      Object.values(entity.shipping_ranges || {}).length > 0
        ? Object.values(entity.shipping_ranges || {}).filter(
            (r) =>
              (orderTotal >= r.min && orderTotal < r.max) ||
              (orderTotal >= r.min && !r.max)
          )
        : null;

    if (selected_shipping_range && selected_shipping_range.length === 1) {
      this.setState({
        shipping_cost: selected_shipping_range[0].value,
        shipping_range_id: selected_shipping_range[0].shipping_range_id,
      });
    }
  }

  onFocus(focus) {
    return (e) => {
      this.setState({ focus });
    };
  }

  checkAddressErrors(field) {
    const {
      publicViewEntity: { entityClientId, entity },
    } = this.context;
    const collectShipAddress = +entity.collect_ship_address === 1;

    if (field.includes("shipping") && this.state.sameAsBilling) {
      return this.checkAddressErrors(field.replace("shipping", "billing"));
    }

    return !(
      (this.state[`${field}_id`] && "ADD" !== this.state[`${field}_id`]) ||
      ((!entityClientId && field.includes("billing")
        ? this.state.billing_address.address_company.trim()
        : this.state[field].address_line_1.trim()) &&
        this.state[field].address_line_1.trim() &&
        (!collectShipAddress ||
          (collectShipAddress &&
            this.state[field].address_city.trim() &&
            this.state[field].address_postal.trim() &&
            this.state[field].address_state.trim() &&
            this.state[field].address_country.trim())))
    );
  }

  checkContactErrors(field) {
    if (field.includes("shipping") && this.state.sameAsBilling) {
      return this.checkContactErrors(field.replace("shipping", "billing"));
    }

    return !(
      (this.state[`${field}_id`] && "ADD" !== this.state[`${field}_id`]) ||
      (this.state[field].contact_name.trim() &&
        validateEmail(this.state[field].contact_email.trim()))
    );
  }

  checkCompanyNameErrors() {
    return !!!this.state.billing_address.address_company.trim();
  }

  checkAdditionalInfoErrors() {
    const {
      publicViewEntity: { entity },
    } = this.context;

    return (
      (entity.collect_customer_po === "REQUIRED" && !this.state.customer_po) ||
      Object.values(entity.metadata_list).some(
        (m) =>
          +m.mandatory === 1 &&
          !!!(this.state.order_metadata[m.metadata_id] || { value: "" }).value
      )
    );
  }

  checkPanelErrors(panel) {
    let result = false;
    switch (panel) {
      case PANELS.COMPANY_NAME:
        result = this.checkCompanyNameErrors();
        break;
      case PANELS.BILLING_INFO:
        result =
          this.checkAddressErrors("billing_address") ||
          this.checkContactErrors("billing_contact");
        break;
      case PANELS.SHIPPING_INFO:
        const {
          publicViewEntity: { entity },
        } = this.context;
        if (+entity.collect_ship_address === 1 && this.isShopAggregate()) {
          result = this.checkAddressErrors("shipping_address");
        } else {
          result =
            this.checkAddressErrors("shipping_address") ||
            this.checkContactErrors("shipping_contact");
        }
        break;
      case PANELS.CUSTOMER_INFO:
        result = this.checkContactErrors("order_contact");
        break;
      case PANELS.ADDITIONAl_INFO:
        result = this.checkAdditionalInfoErrors();
        break;
      case PANELS.COMMENTS: // optional
      default:
        break;
    }
    return result;
  }

  toggleOpenPanel(panel) {
    const hasErrors = this.checkPanelErrors(panel);
    const showAddInfo = this.showMetadataList();
    let panelNum = PANELS_ORDER[panel];
    panelNum = panelNum === 3 ? 4 : panelNum;
    panelNum = panelNum === 4 && !showAddInfo ? 5 : panelNum;

    const {
      publicViewEntity: { entity },
    } = this.context;
    let nextPanelNum = panelNum ? panelNum + 1 : null;
    if (
      +entity.collect_ship_address === 1 &&
      this.isShopAggregate() &&
      this.state.openPanel === PANELS.CUSTOMER_INFO
    ) {
      nextPanelNum = 3;
    }

    const nextPanel =
      (panelNum
        ? Object.keys(PANELS_ORDER).find(
            (k) => PANELS_ORDER[k] === nextPanelNum
          )
        : "") || "";

    const openPanel =
      panel === this.state.openPanel && !hasErrors ? nextPanel : panel;
    const newState = { openPanel: openPanel };
    if (
      openPanel !== panel &&
      get(this.state[`PANEL_${openPanel}`], ["opened"], false)
    ) {
      newState.openPanel = "";
    }
    newState[`PANEL_${this.state.openPanel}`] = {
      ...this.state[`PANEL_${this.state.openPanel}`],
      showPreview: true,
      showCheckmark: true,
      showEditBtn: true,
      opened: true,
    };
    if (panel === this.state.openPanel && hasErrors) {
      newState[`PANEL_${panel}`].showErrors = true;
    }

    this.setState(newState);
  }

  isPanelOpen(panel) {
    return this.state.openPanel === panel;
  }

  isShopAggregate() {
    const {
      publicViewEntity: { entityAggregate },
    } = this.context;
    return +entityAggregate === 1;
  }

  showCompanyName() {
    const {
      publicViewEntity: { entityClientId, entity },
    } = this.context;
    return !(!!entityClientId || "TYPED" !== entity.billing_address_type);
  }

  showMetadataList() {
    const {
      publicViewEntity: { entity, buyInventory },
    } = this.context;
    return (
      entity &&
      !buyInventory &&
      (Object.keys(entity.metadata_list || {}).length > 0 ||
        entity.collect_customer_po !== "NO")
    );
  }

  showComments() {
    const {
      publicViewEntity: { buyInventory },
    } = this.context;
    return !buyInventory;
  }

  renderCompanyName() {
    const {
      publicViewEntity: { entityClientId },
      publicViewTemplate: { templateColor },
    } = this.context;
    if (!this.showCompanyName()) {
      return;
    }
    const { focus } = this.state;
    const address = this.state.billing_address;
    const onChange = (e) => {
      this.setState({
        billing_address: { ...address, address_company: e.target.value },
        errors: { ...this.state.errors, billing_addressaddress_company: false },
      });
    };

    const isOpen = this.isPanelOpen(PANELS.COMPANY_NAME);
    const PanelContent = (
      <Row>
        <Col xs className="billing" style={{ paddingBottom: 5 }}>
          {this.state.PANEL_COMPANY_NAME.showErrors &&
            this.checkCompanyNameErrors() && (
              <Text
                as="div"
                className="error"
                style={{ fontSize: 15, marginLeft: 0 }}
              >
                Missing company name
              </Text>
            )}
        </Col>
        <Col xs className="billing">
          <Input
            id="company_name"
            type="text"
            placeholder="Company Name"
            value={address.address_company}
            onChange={onChange}
            onFocus={this.onFocus("billing_addressaa0")}
            style={{
              ...(focus === "billing_addressaa0"
                ? { border: `1px solid ${templateColor}` }
                : {}),
              width: "100%",
              marginBottom: 0,
            }}
          />
        </Col>
      </Row>
    );

    const hasErrors = this.checkPanelErrors(PANELS.COMPANY_NAME);
    // highlight on shipping/billing info panels open and invalid billing company_name
    const showAddrCompanyNameErr =
      this.isPanelOpen(PANELS.BILLING_INFO) &&
      this.state.PANEL_BILLING_INFO.showErrors &&
      !entityClientId &&
      !address.address_company.trim();

    return (
      <CheckoutPanel
        title="Company Name"
        panel={PANELS.COMPANY_NAME}
        content={PanelContent}
        isOpen={isOpen}
        template_color={templateColor}
        hasErrors={hasErrors}
        showCheckmark={this.state[`PANEL_${PANELS.COMPANY_NAME}`].showCheckmark}
        showEditButton={this.state[`PANEL_${PANELS.COMPANY_NAME}`].showEditBtn}
        previewContent={
          this.state.PANEL_COMPANY_NAME.showPreview && (
            <Text as="p">{address.address_company}</Text>
          )
        }
        toggleOpenPanel={this.toggleOpenPanel}
        borderError={showAddrCompanyNameErr}
      />
    );
  }

  mapSelectContact(c) {
    return {
      ...c,
      key: c.contact_id,
      value: `${c.contact_first_name} ${c.contact_last_name}`,
    };
  }

  mapSelectAddress(a) {
    return { ...a, key: a.address_id, value: a.address_name };
  }

  renderSelectAddress(field, addresses, hide_select, disabled = false) {
    const id = `${field}_id`;
    const panel = field.includes("billing")
      ? "PANEL_BILLING_INFO"
      : field === "order_address"
      ? "PANEL_CUSTOMER_INFO"
      : "PANEL_SHIPPING_INFO";
    const hasErrors =
      this.state[panel].showErrors && this.checkAddressErrors(field);

    return (
      <SelectAddress
        field={field}
        addressId={this.state[id]}
        address={this.state[field]}
        addresses={addresses}
        hide_select={hide_select}
        hasErrors={hasErrors}
        disabled={disabled}
        onSelectAddress={(address_id) =>
          this.setState({
            [id]: address_id,
            errors: {
              ...this.state.errors,
              [id]: false,
              [`${field}address_company`]: false,
              [`${field}address_line_1`]: false,
            },
          })
        }
        onAddAddress={(form) => {
          const address = this.state[field];
          this.setState({
            [id]: "ADD",
            [field]: { ...address, ...form },
            errors: {
              ...this.state.errors,
              [`${id}`]: false,
              [`${field}address_line_1`]: !!!form.address_line_1,
              [`${field}address_line_2`]: false,
              [`${field}address_city`]: !!!form.address_city,
              [`${field}address_country`]: !!!form.address_country,
              [`${field}address_state`]: !!!form.address_state,
              [`${field}address_postal`]: !!!form.address_postal,
            },
          });
        }}
      />
    );
  }

  renderBillingAddress() {
    const { addresses } = this.props;
    const {
      contact,
      publicViewEntity: { entity, entityClientId },
    } = this.context;

    const hasClient = entityClientId || contact?.company_id;
    let billing_addresses = addresses
      .filter((a) => "BILLING" === a.address_type)
      .map(this.mapSelectAddress);

    if (hasClient) {
      let hide_select = 0;
      if (
        entity.billing_address_type === "TYPED" &&
        billing_addresses.length === 1
      ) {
        hide_select = 1;
      }

      return this.renderSelectAddress(
        "billing_address",
        billing_addresses,
        hide_select
      );
    }

    return this.renderEnterAddress("billing_address");
  }

  renderShippingAddress() {
    const { addresses } = this.props;
    const {
      contact,
      publicViewEntity: { entity, entityClientId },
    } = this.context;

    const hasClient = entityClientId || contact?.company_id;
    let shipping_addresses = addresses
      .filter((a) => "SHIPPING" === a.address_type)
      .map(this.mapSelectAddress);

    if (hasClient && !this.isShopAggregate()) {
      let hide_select = 0;
      if (
        entity.shipping_address_type === "TYPED" &&
        shipping_addresses.length === 1
      ) {
        hide_select = 1;
      }

      return this.renderSelectAddress(
        "shipping_address",
        shipping_addresses,
        hide_select,
        !!entity.inventory
      );
    }

    return this.renderEnterAddress("shipping_address");
  }

  handleToggleSameAsBilling(e) {
    //e.preventDefault();
    this.setState((prevState, props) => ({
      sameAsBilling: !prevState.sameAsBilling,
    }));
  }

  getBillingInfo() {
    const {
      billing_contact_id,
      billing_contact,
      billing_address_id,
      billing_address,
    } = this.state;
    return {
      contact:
        billing_contact_id && "ADD" !== billing_contact_id
          ? { contact_id: billing_contact_id }
          : billing_contact,
      address:
        billing_address_id && "ADD" !== billing_address_id
          ? { address_id: billing_address_id }
          : billing_address,
    };
  }

  getShippingInfo() {
    const {
      shipping_contact_id,
      shipping_address_id,
      shipping_contact,
      shipping_address,
      billing_address,
      sameAsBilling,
    } = this.state;
    return sameAsBilling
      ? false
      : {
          contact:
            shipping_contact_id && "ADD" !== shipping_contact_id
              ? { contact_id: shipping_contact_id }
              : shipping_contact,
          address:
            shipping_address_id && "ADD" !== shipping_address_id
              ? { address_id: shipping_address_id }
              : {
                  ...shipping_address,
                  address_company:
                    shipping_address.address_company ||
                    billing_address.address_company,
                },
        };
  }

  getShippingAddress() {
    const { getAddress } = this.props;
    const {
      publicViewEntity: { entity, entityClientId, entityAggregate },
    } = this.context;
    if (entityClientId && entity.shipping_address_id !== ''
      && +entityAggregate === 1
      && (+entity.collect_ship_address === 0
        || +entity.calculate_checkout_taxes === 0
        || +entity.tax_custom_ship_address === 0
      )
    ) {
      return getAddress(entity.shipping_address_id);
    }
    const shippingInfo = this.getShippingInfo();
    if (shippingInfo) {
      if (shippingInfo.address.address_id) {
        return getAddress(shippingInfo.address.address_id);
      }
      return shippingInfo.address;
    }
    return this.getBillingInfo().address;
  }

  renderAddress(address_id) {
    const address = this.props.getAddress(address_id);
    return (
      <Address className="selected-detail" key={address_id} {...address} />
    );
  }

  renderSelectContact(field, contacts, hide_select, disabled = false) {
    const id = `${field}_id`;
    const selectedId =
      this.state[id] && this.state[id] !== "ADD" ? this.state[id] : "";
    let selectedContact =
      selectedId && find(contacts, (v) => v.contact_id === selectedId);
    if (
      !selectedContact &&
      this.state[field] &&
      this.state[field]["contact_email"]
    ) {
      selectedContact = this.state[field];
    }
    const panel = field.includes("billing")
      ? "PANEL_BILLING_INFO"
      : field === "order_contact"
      ? "PANEL_CUSTOMER_INFO"
      : "PANEL_SHIPPING_INFO";
    const hasErrors =
      this.state[panel].showErrors && this.checkContactErrors(field);

    return (
      <SelectContact
        field={field}
        contactId={this.state[id]}
        contact={this.state[field]}
        contacts={contacts}
        hide_select={hide_select}
        hasErrors={hasErrors}
        disabled={disabled}
        onSelectContact={(contact_id) =>
          this.setState({
            [id]: contact_id,
            errors: {
              ...this.state.errors,
              [id]: false,
              [`${field}contact_name`]: false,
              [`${field}contact_email`]: false,
            },
          })
        }
        onAddContact={(form) => {
          const contact = this.state[field];
          this.setState({
            [id]: "ADD",
            [field]: { ...contact, ...form },
            errors: {
              ...this.state.errors,
              [`${id}`]: false,
              [`${field}contact_name`]: !!!form.contact_name,
              [`${field}contact_email`]: !!!form.contact_email,
            },
          });
        }}
      />
    );
  }

  checkCoupon() {
    const { onCreateErrorPopup } = this.props;
    const {
      publicViewEntity: { entityId, entityOrder },
    } = this.context;

    const { code, orderTotal, order_contact, applied_discount } = this.state;

    if (code === "") {
      return;
    }

    const data = {
      parent_id: entityId,
      code,
      subtotal: orderTotal,
      currency_symbol: entityOrder.currency_symbol,
    };
    if (order_contact && order_contact.contact_email !== "") {
      data.email = order_contact.contact_email.trim();
    }
    if (applied_discount) {
      data.code = applied_discount.code;
    }

    return oauth("GET", "discount/verify", data).then(({ json }) => {
      if (json.discount) {
        this.setState({
          errors: { ...this.state.errors, discount: "" },
          applied_discount: json.discount,
          avalara_summary: false,
        });
      } else {
        let errMsg = json.error || "Unable to apply coupon";
        if (errMsg) {
          if (errMsg === "Invalid discount code") {
            errMsg = "Sorry, your coupon code is invalid!";
          }
          const onCloseErrPopup = () => {
            this.setState({
              code: "",
              errors: { ...this.state.errors, discount: "" },
            });
          };
          onCreateErrorPopup(errMsg, onCloseErrPopup);
        }
        this.setState({
          code: "",
          errors: { ...this.state.errors, discount: errMsg },
          avalara_summary: false,
        });
      }

      return json;
    });
  }

  checkFreeShopEligibility() {
    const { onCreateErrorPopup } = this.props;
    const {
      publicViewEntity: { entityId, entityType, entity },
    } = this.context;
    const { order_contact } = this.state;
    if (entityType === "ORDER") {
      return new Promise((resolve, reject) => {
        resolve({ verifyFreeShop: "false" });
      });
    }

    const data = {
      shop_id: entityId,
      single_use: entity.single_use_for_free_shop,
    };

    if (order_contact && order_contact.contact_email !== "") {
      data.email = order_contact.contact_email.trim();
    }

    return oauth("GET", "shop/verify", data).then(({ json }) => {
      if (json.error) {
        onCreateErrorPopup(json.error);
      }
      return json;
    });
  }

  renderEnterCouponSection() {
    const { code, errors } = this.state;
    return (
      <EnterCouponSection
        checkCoupon={() => this.checkCoupon()}
        onChangeCode={(code) => {
          this.setState({ code, avalara_summary: false });
        }}
        code={code}
        errors={errors}
      />
    );
  }

  isCheckoutTaxValid() {
    const { getAddress } = this.props;
    const {
      publicViewEntity: { entity, entityAggregate, },
    } = this.context;

    const { shipping_address_id } = this.state;
    let shipping_address = shipping_address_id !== '' && shipping_address_id !== 'ADD' ? getAddress(shipping_address_id) : null;

    const taxCustomShipAddress = +entity.collect_ship_address === 1
      && +entity.tax_custom_ship_address === 1
      && +entity.calculate_checkout_taxes === 1;
    if (taxCustomShipAddress) {
      shipping_address = this.getShippingAddress();
    }

    const canCollectTaxes = +entityAggregate !== 1 || (
      +entityAggregate === 1 && +entity.calculate_checkout_taxes === 1
    );
    if (!this.isAvalara() && canCollectTaxes) {
      if (+entityAggregate === 1) {
        const shopOptions = getShopOptions(entity?.shop_options);
        if (taxCustomShipAddress && !this.state.errors.checkout_tax && shopOptions?.use_zip2tax_default) {
          return Boolean(shipping_address.tax_id);
        }
      }
    }

    return true;
  }

  calculateCheckoutAmounts() {
    const { getAddress } = this.props;
    const {
      publicViewEntity: { entity, entityAggregate },
    } = this.context;

    const { shipping_address_id, orderTotal, applied_discount, avalara_summary } = this.state;
    let shipping_address = shipping_address_id !== '' && shipping_address_id !== 'ADD' ? getAddress(shipping_address_id) : null;

    const taxCustomShipAddress = +entity.collect_ship_address === 1
      && +entity.tax_custom_ship_address === 1
      && +entity.calculate_checkout_taxes === 1;
    if (taxCustomShipAddress) {
      shipping_address = this.getShippingAddress();
    }

    const canCollectTaxes = +entityAggregate !== 1 || (
      +entityAggregate === 1 && +entity.calculate_checkout_taxes === 1
    );

    let tax = null;
    if (!this.isAvalara() && canCollectTaxes) {
      if (entity.allow_credit_card_checkout == 1) {
        if (
          +entityAggregate === 0 &&
          shipping_address &&
          shipping_address.tax_id &&
          parseFloat(shipping_address.percent) !== 0
        ) {
          tax = {
            tax_id: shipping_address.tax_id,
            label: shipping_address.label,
            percent: shipping_address.percent,
          };
        }
      }

      if (+entityAggregate === 1) {
        const shopOptions = getShopOptions(entity?.shop_options);
        if (taxCustomShipAddress && !this.state.errors.checkout_tax && shopOptions?.use_zip2tax_default) {
          if (shipping_address.tax_id) {
            tax = {
              tax_id: shipping_address.tax_id,
              label: shipping_address.tax_label,
              percent: shipping_address.tax_percent,
            };
          }
        } else if (entity.default_tax_id && parseFloat(entity.default_tax_percent) !== 0) {
          tax = {
            tax_id: entity.default_tax_id,
            label: entity.default_tax_label,
            percent: entity.default_tax_percent
          };
        }
      }
    }

    let modifiedTotal = orderTotal;
    let discountValue = 0;
    if (+entityAggregate === 1 && applied_discount) {
      discountValue = applied_discount.subtracted_value;
      modifiedTotal = orderTotal - discountValue;
    }
    modifiedTotal = Math.max(modifiedTotal, 0);

    const ignore_tax_on_shipping =
      (+entityAggregate === 1
        ? entity.ignore_tax_on_shipping
        : shipping_address && shipping_address.ignore_tax_on_shipping) == 1;
    let taxableTotal = ignore_tax_on_shipping
      ? modifiedTotal
      : modifiedTotal + this.state.shipping_cost;
    taxableTotal = Number(parseFloat(taxableTotal).toFixed(4));
    const taxTotal = tax ? taxableTotal * (tax.percent / 100) : 0;
    const avalaraTotal = sumBy(avalara_summary, "taxCalculated") || 0;
    const checkoutTotal = ignore_tax_on_shipping
      ? formatMoney(
          taxableTotal + taxTotal + this.state.shipping_cost + avalaraTotal
        )
      : formatMoney(taxableTotal + taxTotal + avalaraTotal);
    return { tax, taxTotal, checkoutTotal, discountValue };
  }

  isAvalara() {
    const {
      publicViewEntity: { entity, entityAggregate },
    } = this.context;
    const address = this.getShippingAddress();
    return +entityAggregate === 1
      ? isAvalaraShop(entity)
      : get(address, "tax_id") === Tax.avalara_tax_id;
  }

  handleChangeMetaData(label, value, metadata_id) {
    this.setState(
      {
        order_metadata: Object.assign({}, this.state.order_metadata, {
          [metadata_id]: {
            label,
            value,
          },
        }),
        errors: {},
      },
      function () {
        console.log(this.state.errors);
      }
    );
  }

  checkShopCheckoutLimit() {
    const {
      publicViewEntity: { entity, entityAggregate, perCheckoutLimit },
      onClickCart,
    } = this.context;

    if (
      +entityAggregate === 1 &&
      perCheckoutLimit &&
      this.props.orderTotal > parseInt(perCheckoutLimit)
    ) {
      onClickCart();
      return true;
    }
    return false;
  }

  //if no_fulfillment is set to true, the checkout would not be fulfilled instantly
  //mainly for development to test if cron job is working
  handleSubmitOrder(e, no_fulfillment = false) {
    e.preventDefault();
    const {
      publicViewEntity: {
        entity,
        entityAggregate,
        entityIsFree,
        entityBuyInventory,
      },
      contact,
    } = this.context;
    const {
      shipping_contact_id,
      billing_contact_id,
      shipping_address_id,
      billing_address_id,
      shipping_contact,
      billing_contact,
      shipping_address,
      billing_address,
      sameAsBilling,
      comment,
      order_contact,
      shipping_range_id,
      order_metadata,
      cart,
      applied_discount,
      customer_po,
    } = this.state;

    const { checkoutTotal } = this.calculateCheckoutAmounts();

    if (+entityAggregate === 0 && !this.isOkToCheckout()) {
      console.log("checkout missing info...");
      return;
    }

    if (this.checkShopCheckoutLimit()) {
      return;
    }

    const billing_info = this.getBillingInfo();
    const shipping_info = this.getShippingInfo();
    const breakdowns = cart.items.reduce(
      (b, i) => b.concat(
        i.breakdowns.filter(b => +b.quantity > 0)
      ),
      []
    );
    const artworks = cart.items.reduce(
      (art, i) => ({
        ...art,
        ...i.artworks.reduce((o, a) => {
          o[a.artwork_id] = a.artwork_file_id;
          return o;
        }, {}),
      }),
      {}
    );

    const customer =
      order_contact.contact_name !== "" &&
      order_contact.contact_email !== "" &&
      validateEmail(order_contact.contact_email.trim())
        ? order_contact
        : null;

    let _this = this;
    let metadata_error = false;
    if (+entityAggregate === 1) {
      forEach(entity.metadata_list, function (value, key) {
        if (
          (!order_metadata[key] || order_metadata[key].value == "") &&
          value.mandatory == 1
        ) {
          // Update (not overwrite) errors obj with new data
          _this.setState((prevState) => {
            return {
              errors: { ...prevState.errors, [`metadata_${key}`]: true },
            };
          });
          metadata_error = true;
        }
      });
    }

    if (+entityAggregate === 1) {
      if (customer === null) {
        // Update (not overwrite) errors obj with new data
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              order_contactcontact_name: order_contact.contact_name === "",
              order_contactcontact_email:
                order_contact.contact_email === "" ||
                !validateEmail(order_contact.contact_email.trim()),
            },
          };
        });

        console.log("checkout missing `customer` info...", this.state.errors);
        return;
      }
    }

    if (metadata_error) {
      console.log("checkout missing info...");
      return;
    }

    const client_id = contact?.company_id ?? null;
    //check if free shop and if elegible for free shop
    if (+entityIsFree === 1) {
      this.checkFreeShopEligibility()
        .then((json) => {
          if (json.error) {
            return;
          }
          if (json.verifyFreeShop == "true") {
            Promise.resolve(
              this.props.onCheckout(
                billing_info,
                shipping_info,
                breakdowns,
                artworks,
                comment,
                false,
                shipping_range_id,
                customer,
                checkoutTotal,
                order_metadata,
                null,
                customer_po,
                true,
                client_id,
                this.props.navigate
              )
            ).then(() => {
              this.setState({ avalara_summary: false });
            });
          }
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    } else {
      if (applied_discount) {
        this.checkCoupon().then((json) => {
          if (json.error) {
            _this.props.onCreateErrorPopup(json.error);
            _this.setState({ applied_discount: null });
            return;
          }

          if (entity.allow_credit_card_checkout == 1 && checkoutTotal > 0) {
            _this.handlePayOrder(
              billing_info,
              shipping_info,
              breakdowns,
              artworks,
              comment,
              customer,
              no_fulfillment
            );
          } else {
            Promise.resolve(
              _this.props.onCheckout(
                billing_info,
                shipping_info,
                breakdowns,
                artworks,
                comment,
                false,
                shipping_range_id,
                customer,
                checkoutTotal,
                order_metadata,
                applied_discount,
                customer_po,
                true,
                client_id,
                this.props.navigate
              )
            ).then(() => {
              this.setState({ avalara_summary: false });
            });
          }
        });
      } else {
        if (
          entity.allow_credit_card_checkout == 1 &&
          checkoutTotal > 0 &&
          !entityBuyInventory
        ) {
          this.handlePayOrder(
            billing_info,
            shipping_info,
            breakdowns,
            artworks,
            comment,
            customer,
            no_fulfillment
          );
        } else {
          Promise.resolve(
            this.props.onCheckout(
              billing_info,
              shipping_info,
              breakdowns,
              artworks,
              comment,
              false,
              shipping_range_id,
              customer,
              checkoutTotal,
              order_metadata,
              null,
              customer_po,
              true,
              client_id,
              this.props.navigate
            )
          ).then(() => {
            this.setState({ avalara_summary: false });
          });
        }
      }
    }
  }

  handlePayOrder(
    billing_info,
    shipping_info,
    breakdowns,
    artworks,
    comment,
    customer,
    no_fulfillment
  ) {
    const { onCheckout, onCreateErrorPopup } = this.props;
    const { shipping_range_id, order_metadata, applied_discount, customer_po } =
      this.state;
    const {
      publicViewEntity: { entity, entityId, entityType, entityTenantId },
    } = this.context;
    const { checkoutTotal } = this.calculateCheckoutAmounts();

    if (entity.company_data?.credit_card_software === "STRIPE") {
      if (!entity.company_data?.stripe_public_key) {
        alert(
          "Sorry but we could not proceed currently: credit card software is not properly set up. Please contact your administrator of this shop."
        );
        return;
      }

      onCheckout(
        billing_info,
        shipping_info,
        breakdowns,
        artworks,
        comment,
        null,
        shipping_range_id,
        customer,
        checkoutTotal,
        order_metadata,
        applied_discount,
        customer_po,
        false,
        null,
        this.props.navigate
      )
        .then((json) => {
          this.setState({ avalara_summary: false });

          let stripe = window.Stripe(entity.company_data?.stripe_public_key);
          let stripe_params = {
            action: "create-product-session",
            parent_id: entityId,
            parent_type: entityType,
            company_id: entityTenantId,
            amount: formatMoney(checkoutTotal),
            pay_order_id: json.order.order_id,
            no_fulfillment,
          };

          oauth("POST", "stripe", stripe_params)
            .then((res) => {
              stripe
                .redirectToCheckout({
                  sessionId: res.json.session_id,
                })
                .then(function (result) {
                  if (result.error) {
                    alert(result.error.message);
                  }
                })
                .catch(function (error) {
                  alert(error.message);
                });
            })
            .catch((error) => {
              if (error) {
                onCreateErrorPopup("Failed to create session: " + error);
              }
            });
        })
        .catch((error) => {
          if (error) {
            onCreateErrorPopup("Failed to create order: " + error);
          }
        });
      return;
    }

    if (
      entity.company_data?.credit_card_software === "CARDCONNECT" &&
      !entity.company_data?.empty_cardconnect_merchid &&
      entity.company_data?.cardconnect_api_ready
    ) {
      this.props.onCreateCardConnectPopup(
        entity,
        checkoutTotal,
        billing_info,
        shipping_info,
        breakdowns,
        artworks,
        comment,
        this.state.shipping_range_id,
        customer,
        this.state.order_metadata,
        this.state.applied_discount,
        this.state.customer_po
      );
      return;
    }

    alert(
      "Sorry but we could not proceed currently: credit card software is not properly set up. Please contact your administrator of this shop."
    );
  }

  isOkToCheckout() {
    const {
      shipping_contact_id,
      billing_contact_id,
      shipping_address_id,
      billing_address_id,
      shipping_contact,
      billing_contact,
      shipping_address,
      billing_address,
      sameAsBilling,
      cart,
      customer_po,
    } = this.state;
    const {
      publicViewEntity: {
        entity,
        entityAggregate,
        entityClientId,
        entityBuyInventory,
        entityForceMinimumQty,
      },
    } = this.context;
    const minimumQuantityOk =
      !!cart.items.length &&
      cart.items.reduce((ok, i) => {
        if (!ok) {
          return ok;
        }
        if (!entityBuyInventory && i.inventory_items.length > 0) {
          return i.quantity > 0;
        } else if (
          (+entityAggregate === 1 && +entityForceMinimumQty === 1) ||
          +entityAggregate === 0
        ) {
          return i.quantity >= i.minimum_quantity;
        } else {
          return i.quantity > 0;
        }
      }, true);
    const billingContactOk =
      (billing_contact_id && "ADD" !== billing_contact_id) ||
      (billing_contact.contact_name.trim() &&
        validateEmail(billing_contact.contact_email.trim()));
    const shippingContactOk = sameAsBilling
      ? billingContactOk
      : (shipping_contact_id && "ADD" !== shipping_contact_id) ||
        (shipping_contact.contact_name.trim() &&
          validateEmail(shipping_contact.contact_email.trim()));
    const billingAddressOk =
      (billing_address_id && "ADD" !== billing_address_id) ||
      (!entityClientId
        ? billing_address.address_company.trim() &&
          billing_address.address_line_1.trim()
        : billing_address.address_line_1.trim());
    const shippingAddressOk = sameAsBilling
      ? billingAddressOk
      : (shipping_address_id && "ADD" !== shipping_address_id) ||
        (!entityClientId
          ? shipping_address.address_line_1.trim()
          : shipping_address.address_line_1.trim());
    const customerPOOk =
      !!entityBuyInventory ||
      entity.collect_customer_po !== "REQUIRED" ||
      customer_po.trim().length > 0;

    this.setState({
      errors: {
        billing_contact_id:
          "LIST" === entity.billing_contact_type && !billing_contact_id,
        billing_contactcontact_name: !billing_contact.contact_name.trim(),
        billing_contactcontact_email: !validateEmail(
          billing_contact.contact_email.trim()
        ),
        shipping_contact_id:
          "LIST" === entity.shipping_contact_type && !shipping_contact_id,
        shipping_contactcontact_name: !shipping_contact.contact_name.trim(),
        shipping_contactcontact_email: !validateEmail(
          shipping_contact.contact_email.trim()
        ),
        billing_address_id:
          "LIST" === entity.billing_address_type && !billing_address_id,
        billing_addressaddress_company: !billing_address.address_company.trim(),
        billing_addressaddress_line_1: !billing_address.address_line_1.trim(),
        shipping_address_id:
          "LIST" === entity.shipping_address_type && !shipping_address_id,
        shipping_addressaddress_line_1: !shipping_address.address_line_1.trim(),
        customer_po: !customerPOOk,
      },
    });

    return (
      minimumQuantityOk &&
      billingContactOk &&
      shippingContactOk &&
      billingAddressOk &&
      shippingAddressOk &&
      customerPOOk
    );
  }

  renderCustomer() {
    return (
      <div key="customer" className="small-12 columns customer">
        {this.renderEnterContact("order_contact")}
      </div>
    );
  }

  renderCheckoutButtons(show_for_size) {
    const { avalara_summary, cart, shipping_cost } = this.state;
    const {
      publicViewEntity: { entityOrder, entity },
    } = this.context;
    const collectShipAddress = +entity.collect_ship_address === 1;

    const onChangeAvalaraSummary = (result) =>
      this.setState({ avalara_summary: result });
    const { checkoutTotal, discountValue } = this.calculateCheckoutAmounts();
    const isDisabled =
      (this.showCompanyName() && this.checkPanelErrors(PANELS.COMPANY_NAME)) ||
      (this.isShopAggregate() && this.checkPanelErrors(PANELS.CUSTOMER_INFO)) ||
      (!this.isShopAggregate() && this.checkPanelErrors(PANELS.BILLING_INFO)) ||
      ((!this.isShopAggregate() ||
        (this.isShopAggregate() && collectShipAddress)) &&
        this.checkPanelErrors(PANELS.SHIPPING_INFO)) ||
      (this.showMetadataList() &&
        this.checkPanelErrors(PANELS.ADDITIONAl_INFO)) ||
      this.checkPanelErrors(PANELS.COMMENTS);

    const shippingTaxAddress = this.getShippingAddress(true);
    const shopOptions = getShopOptions(entity?.shop_options);
    const estimateTaxByAddress = collectShipAddress &&
      this.isShopAggregate() &&
      +entity.calculate_checkout_taxes === 1 &&
      +entity.tax_custom_ship_address === 1 &&
      !this.state.errors.checkout_tax &&
      (shopOptions?.use_zip2tax_default || this.isAvalara()) &&
      !this.isCheckoutTaxValid();

    return (
      <CheckoutButtons
        order={entityOrder}
        cart={cart}
        checkoutTotal={checkoutTotal}
        discountValue={discountValue}
        shippingAddress={shippingTaxAddress}
        shipping_cost={shipping_cost}
        avalara_summary={avalara_summary}
        isAvalara={this.isAvalara()}
        isDisabled={isDisabled}
        show_for_size={show_for_size}
        handleSubmitOrder={this.handleSubmitOrder}
        onChangeAvalaraSummary={onChangeAvalaraSummary}
        estimateTaxByAddress={estimateTaxByAddress}
        error={this.state.errors.checkout_tax}
        onError={(errMsg) => {
          this.setState({
            errors: {
              ...this.state.errors,
              checkout_tax: errMsg,
            },
            shipping_address: {
              ...this.state.shipping_address,
              tax_id: '',
              tax_label: '',
              tax_percent: '',
              tax_description: '',
            },
          });
        }}
        onChangeAddressTax={(v) => {
          if (!v) return;
          this.setState({
            shipping_address: {
              ...this.state.shipping_address,
              tax_id: v.tax_id,
              tax_label: v.label,
              tax_percent: v.percent,
              tax_description: v.description,
            },
          });
        }}
      />
    );
  }

  renderOrderTotal() {
    const { cart } = this.props;
    const { orderTotal, shipping_cost, applied_discount, avalara_summary } =
      this.state;
    const {
      publicViewEntity: { entityOrder },
    } = this.context;

    const { tax, taxTotal, checkoutTotal, discountValue } =
      this.calculateCheckoutAmounts();
    const EnterCouponSectionComponent = this.renderEnterCouponSection();
    const CheckoutButtonsComponent = this.renderCheckoutButtons("");
    const CartItemsComponent = cart && <CheckoutCartItems cart={cart} />;
    const hasCheckoutTaxError = Boolean(this.state.errors.checkout_tax);

    return (
      <OrderTotal
        order={entityOrder}
        orderTotal={orderTotal}
        shipping_cost={shipping_cost}
        applied_discount={applied_discount}
        avalara_summary={avalara_summary}
        tax={tax}
        taxTotal={taxTotal}
        checkoutTotal={checkoutTotal}
        discountValue={discountValue}
        isAvalara={this.isAvalara()}
        onDeleteCoupon={() => this.setState({ applied_discount: null, code: '', avalara_summary: false })}
        CartItems={CartItemsComponent}
        EnterCouponSection={EnterCouponSectionComponent}
        CheckoutButtons={CheckoutButtonsComponent}
        hasCheckoutTaxError={hasCheckoutTaxError}
      />
    );
  }

  renderEnterAddress(field) {
    const address = this.state[field];
    const onChange = (target) => (e) => {
      this.setState({
        [`${field}__validated`]: false,
        [field]: {
          ...address,
          [target]: e.target ? e.target.value : e.value,
        },
        errors: {
          ...this.state.errors,
          [`${field}${target}`]: false,
        },
      });
    };

    const onUpdateAddress = (newAddress) => {
      const shouldResetCheckoutTax = field === 'shipping_address'
        && +entity.calculate_checkout_taxes === 1
        && +entity.tax_custom_ship_address === 1;
      this.setState({
        [`${field}__validated`]: false,
        [field]: {
          ...address,
          ...(shouldResetCheckoutTax ? {
            tax_id: '',
            tax_label: '',
            tax_percent: '',
            tax_description: '',
          } : {}),
          ...newAddress
        },
        ...(shouldResetCheckoutTax ? {avalara_summary: false} : {}),
        errors: {
          ...this.state.errors,
          ...(shouldResetCheckoutTax ? {checkout_tax: ''} : {}),
          ...(Object.keys(newAddress).reduce(
            (acc, k) => ({ ...acc, [`${field}${k}`]: false }),
            {}
          )),
        },
      });
    };

    const panel = field.includes("billing")
      ? "PANEL_BILLING_INFO"
      : field === "order_address"
      ? "PANEL_CUSTOMER_INFO"
      : "PANEL_SHIPPING_INFO";

    const entity = this.context.publicViewEntity.entity;
    const collect_ship_address = +entity.collect_ship_address;

    let allowed_countries = "ALL";
    if ("shipping_address" === field && collect_ship_address === 1) {
      try {
        const shopOptions = getShopOptions(entity?.shop_options);
        allowed_countries = shopOptions?.ship_address_countries || ['us'];
      } catch (error) {
        allowed_countries = ["us"];
      }

      if (allowed_countries?.length === 0) {
        allowed_countries = "ALL";
      }
    }

    return (
      <EnterAddress
        field={field}
        address={address}
        showErrors={this.state[panel].showErrors}
        onChange={onChange}
        onUpdateAddress={onUpdateAddress}
        onFocus={this.onFocus}
        allowed_countries={allowed_countries}
        required_full_address={collect_ship_address === 1}
      />
    );
  }

  renderEnterContact(field) {
    const contact = this.state[field];
    const onChange = (target) => (e) => {
      this.setState({
        [field]: {
          ...contact,
          [target]: e.target.value,
        },
        errors: {
          ...this.state.errors,
          [`${field}${target}`]: false,
        },
      });
    };
    const panel = field.includes("billing")
      ? "PANEL_BILLING_INFO"
      : field === "order_contact"
      ? "PANEL_CUSTOMER_INFO"
      : "PANEL_SHIPPING_INFO";

    return (
      <EnterContact
        contact={contact}
        field={field}
        onChange={onChange}
        showErrors={this.state[panel].showErrors}
        onFocus={this.onFocus}
      />
    );
  }

  renderContactEmail(contact_id) {
    const contact = this.props.getContact(contact_id);
    return (
      <p key={contact_id} className="selected-detail">
        {contact.contact_email}
      </p>
    );
  }

  renderBillingContact() {
    const { contacts } = this.props;
    const {
      contact,
      publicViewEntity: { entity, entityType, entityClientId },
    } = this.context;

    const hasClient = entityClientId || contact?.company_id;

    let billing_contacts = contacts
      .filter(
        (c) =>
          [entityType, `${entityType}-BILLING`].indexOf(c.company_type) !== -1
      )
      .map(this.mapSelectContact);

    if (hasClient) {
      let hide_select = 0;
      if (
        entity.billing_contact_type === "TYPED" &&
        billing_contacts.length === 1
      ) {
        hide_select = 1;
      }

      return this.renderSelectContact(
        "billing_contact",
        billing_contacts,
        hide_select
      );
    }

    return this.renderEnterContact("billing_contact");
  }

  renderShippingContact() {
    const { contacts } = this.props;
    const {
      contact,
      publicViewEntity: { entity, entityType, entityClientId },
    } = this.context;
    const hasClient = entityClientId || contact?.company_id;

    let shipping_contacts = contacts
      .filter(
        (c) =>
          [entityType, `${entityType}-SHIPPING`].indexOf(c.company_type) !== -1
      )
      .map(this.mapSelectContact);

    if (hasClient) {
      let hide_select = 0;
      if (
        entity.shipping_contact_type === "TYPED" &&
        shipping_contacts.length === 1
      ) {
        hide_select = 1;
      }

      return this.renderSelectContact(
        "shipping_contact",
        shipping_contacts,
        hide_select,
        !!entity.inventory
      );
    }

    return this.renderEnterContact("shipping_contact");
  }

  renderMetadataList() {
    const {
      publicViewEntity: { entityBuyInventory },
    } = this.context;
    const onChangeCustomerPo = (customer_po) =>
      this.setState({
        customer_po,
        errors: { ...this.state.errors, customer_po: false },
      });
    if (!!entityBuyInventory) {
      return null;
    }

    return (
      <MetadataList
        errors={this.state.errors}
        showErrors={this.state.PANEL_ADDITIONAl_INFO.showErrors}
        order_metadata={this.state.order_metadata}
        customer_po={this.state.customer_po}
        handleChangeMetaData={this.handleChangeMetaData}
        onChangeCustomerPo={onChangeCustomerPo}
      />
    );
  }

  renderComment() {
    if (!this.showComments()) {
      return null;
    }

    const onChange = (e) => {
      this.setState({ comment: e.target.value });
    };
    return (
      <div key="comment" className="small-12 columns" style={{ padding: 0 }}>
        <Textarea
          id="checkout-comments"
          placeholder="Comment"
          value={this.state.comment}
          onChange={onChange}
          onFocus={this.onFocus("commentaa0")}
          rows="4"
        />
      </div>
    );
  }

  renderContactAddrInfoPreview(info_type) {
    const hide_contact = false;
    const hide_address = false;

    if (info_type === "shipping" && this.state.sameAsBilling) {
      info_type = "billing";
    }

    const contactId = this.state[`${info_type}_contact_id`];
    const addressId = this.state[`${info_type}_address_id`];
    const contact =
      (contactId &&
        find(this.props.contacts, (v) => v.contact_id === contactId)) ||
      this.state[`${info_type}_contact`];
    const address =
      (addressId &&
        find(this.props.addresses, (v) => v.address_id === addressId)) ||
      this.state[`${info_type}_address`];
    const textStyle = { marginBottom: 0 };

    return (
      <>
        {!hide_contact && (
          <>
            <Text as="p" style={textStyle}>
              {getContactName(contact)}
            </Text>
            <Text as="p" style={textStyle}>
              {get(contact, ["contact_email"])}
            </Text>
            <br />
          </>
        )}
        {!hide_address && (
          <>
            <Text as="p" style={textStyle}>
              {get(address, ["address_line_1"])}
            </Text>
            {address?.address_line_2 && (
              <Text as="p" style={textStyle}>
                {address.address_line_2}
              </Text>
            )}
            {address?.address_line_3 && (
              <Text as="p" style={textStyle}>
                {address.address_line_3}
              </Text>
            )}
            {address?.address_line_4 && (
              <Text as="p" style={textStyle}>
                {address.address_line_4}
              </Text>
            )}
            <Text as="p" style={textStyle}>
              {getAddressCityAndState(address)}
            </Text>
            <Text as="p" style={textStyle}>
              {getAddressPostalCodeAndCountry(address)}
            </Text>
            <br />
          </>
        )}
      </>
    );
  }

  renderCustomerInfoPreview() {
    const contact = this.state.order_contact;
    return (
      <>
        <Text
          as="p"
          style={{ fontFamily: fontFamilies.regular, marginBottom: 0 }}
        >
          {get(contact, ["contact_name"])}
        </Text>
        <Text
          as="p"
          style={{ fontFamily: fontFamilies.regular, marginBottom: 0 }}
        >
          {get(contact, ["contact_email"])}
        </Text>
      </>
    );
  }

  renderAdditionalInfoPreview() {
    const {
      publicViewEntity: { entity },
    } = this.context;

    if (!this.showMetadataList()) {
      return null;
    }
    return (
      <>
        {entity.collect_customer_po !== "NO" ? (
          <Text
            as="p"
            style={{ marginBottom: 1, fontFamily: fontFamilies.regular }}
          >
            <span
              style={{ fontFamily: fontFamilies.demibold, paddingRight: 16 }}
            >
              Customer PO:
            </span>
            {this.state.customer_po}
          </Text>
        ) : null}
        {Object.values(entity.metadata_list).map((m) => (
          <Text
            key={`metadata-${m.metadata_id}`}
            as="p"
            style={{ marginBottom: 1, fontFamily: fontFamilies.regular }}
          >
            <span
              style={{ fontFamily: fontFamilies.demibold, paddingRight: 16 }}
            >
              {m.label}:
            </span>
            {(this.state.order_metadata[m.metadata_id] || { value: "" }).value}
          </Text>
        ))}
      </>
    );
  }

  renderCommentPreview() {
    if (!this.showComments()) {
      return null;
    }
    return <p style={{ whiteSpace: "pre-line" }}>{this.state.comment}</p>;
  }

  render() {
    const { contacts, addresses } = this.props;
    const { sameAsBilling, billing_contact_id, billing_address_id } =
      this.state;
    const {
      publicViewEntity: {
        entity,
        entityType,
        entityClientId,
        entityAggregate,
        entityBuyInventory,
      },
      publicViewTemplate: { templateColor: template_color },
    } = this.context;

    const shopType = getShopType({
      client_id: entityClientId,
      aggregate: entityAggregate,
    });
    const isBillingInfoOpen = this.isPanelOpen(PANELS.BILLING_INFO);
    const isShippingInfoOpen = this.isPanelOpen(PANELS.SHIPPING_INFO);
    const isCustomerInfoOpen = this.isPanelOpen(PANELS.CUSTOMER_INFO);
    const isAdditionalInfoOpen = this.isPanelOpen(PANELS.ADDITIONAl_INFO);
    const isCommentsInfoOpen = this.isPanelOpen(PANELS.COMMENTS);

    const collectShipAddress = +entity.collect_ship_address === 1;
    const canHaveSameContact =
      !entityBuyInventory &&
      (contacts.filter(
        (c) =>
          [entityType, `${entityType}-SHIPPING`].indexOf(c.company_type) !==
            -1 && c.contact_id === billing_contact_id
      ).length === 1 ||
        (billing_contact_id === "ADD" &&
          entity.shipping_contact_type === "TYPED"));
    const canHaveSameAddress =
      !entityBuyInventory &&
      (addresses.filter(
        (a) =>
          "SHIPPING" === a.address_type && a.address_id === billing_address_id
      ).length === 1 ||
        (billing_address_id === "ADD" &&
          entity.shipping_address_type === "TYPED"));
    const sameAsBillingEnabled =
      canHaveSameContact === true && canHaveSameAddress === true;
    const isCompanyShop = shopType === SHOP_TYPES.COMPANY_SHOP;
    const isMarketingShop = shopType === SHOP_TYPES.MARKETING_SHOP;
    const isShipShop =
      (isMarketingShop && !isShippingInfoOpen) ||
      (isCompanyShop &&
        !isShippingInfoOpen &&
        !this.checkPanelErrors(PANELS.SHIPPING_INFO));
    const isBillShop =
      (isMarketingShop && !isBillingInfoOpen) ||
      (isCompanyShop &&
        !isBillingInfoOpen &&
        !this.checkPanelErrors(PANELS.BILLING_INFO));

    const BillingInfoContent = (
      <Row>
        <Col xs>{this.renderBillingContact()}</Col>
        <Col xs>{this.renderBillingAddress()}</Col>
      </Row>
    );
    const ShippingInfoContent = (
      <Row style={{ paddingBottom: 20 }}>
        <Col xs>
          {sameAsBilling
            ? this.renderBillingContact(true)
            : this.renderShippingContact(true)}
        </Col>
        <Col xs>
          {sameAsBilling
            ? this.renderBillingAddress(true)
            : this.renderShippingAddress(true)}
        </Col>
      </Row>
    );
    const ShipingInfoPanelTitle = [
      "Shipping Information",
      !this.isShopAggregate() && sameAsBillingEnabled && isShippingInfoOpen && (
        <div
          key="shipping-info-panel-checkbox"
          style={{ paddingTop: 5, marginLeft: 16, display: "inline-block" }}
        >
          <LabeledCheckbox
            className="same-as-billing"
            label="Same as billing"
            checked={sameAsBilling ? 1 : 0}
            onClick={(e) => {
              this.setState(
                (prevState, props) => ({
                  sameAsBilling: !prevState.sameAsBilling,
                }),
                () => {
                  if (!sameAsBilling) {
                    this.toggleOpenPanel(PANELS.SHIPPING_INFO);
                  }
                }
              );
            }}
            checkboxColor={template_color}
            checkboxHoverColor={toneColor(template_color, 20)}
          />
        </div>
      ),
    ];
    const CommentsPanelTitle = (
      <span>
        Comments{" "}
        {!this.isPanelOpen(PANELS.COMMENTS) ? null : (
          <span
            style={{
              color: "var(--color-neutrals-70)",
              fontFamily: fontFamilies.medium,
              fontWeight: "normal",
            }}
          >
            (optional)
          </span>
        )}
      </span>
    );

    return (
      <div
        className="shop"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background: "#fff",
          maxWidth: 1512,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Csku
          style={{
            xs: {
              marginTop: "24px !important",
              marginLeft: "24px !important",
              marginRight: "24px !important",
            },
            md: {
              marginLeft: "64px !important",
              marginRight: "64px !important",
            },
          }}
        >
          <Row>
            <Col xs>
              <Row>
                <Col xs>
                  <Text
                    style={{
                      fontSize: "30px",
                      fontFamily: fontFamilies.bold,
                      color: "var(--color-neutrals-100)",
                    }}
                  >
                    Checkout
                  </Text>
                </Col>
              </Row>
              <Row style={{ marginTop: 24 }}>
                <Col xs md={6}>
                  {this.renderCompanyName()}
                  {!this.isShopAggregate() ? (
                    <Row>
                      <Col
                        xs
                        md={isBillShop ? 6 : 12}
                        mdStyle={{
                          ...(isBillShop && !isBillingInfoOpen
                            ? { paddingRight: 8 }
                            : {}),
                        }}
                      >
                        <CheckoutPanel
                          title="Billing Information"
                          panel={PANELS.BILLING_INFO}
                          content={BillingInfoContent}
                          isOpen={isBillingInfoOpen}
                          template_color={template_color}
                          hasErrors={this.checkPanelErrors(PANELS.BILLING_INFO)}
                          showCheckmark={
                            this.state[`PANEL_${PANELS.BILLING_INFO}`]
                              .showCheckmark
                          }
                          showEditButton={
                            this.state[`PANEL_${PANELS.BILLING_INFO}`]
                              .showEditBtn
                          }
                          previewContent={
                            this.state.PANEL_BILLING_INFO.showPreview &&
                            this.renderContactAddrInfoPreview("billing")
                          }
                          closeMdSize={9}
                          toggleOpenPanel={this.toggleOpenPanel}
                          editBtnInBody
                          titleWrapperStyle={{ paddingBottom: 14 }}
                        />
                      </Col>
                      <Col
                        xs
                        md={isShipShop ? 6 : 12}
                        mdStyle={{
                          ...(isShipShop &&
                          !this.isPanelOpen(PANELS.BILLING_INFO)
                            ? { paddingLeft: 8 }
                            : {}),
                        }}
                      >
                        <CheckoutPanel
                          title={ShipingInfoPanelTitle}
                          panel={PANELS.SHIPPING_INFO}
                          content={ShippingInfoContent}
                          isOpen={isShippingInfoOpen}
                          template_color={template_color}
                          hasErrors={this.checkPanelErrors(
                            PANELS.SHIPPING_INFO
                          )}
                          showCheckmark={
                            this.state[`PANEL_${PANELS.SHIPPING_INFO}`]
                              .showCheckmark
                          }
                          showEditButton={
                            this.state[`PANEL_${PANELS.SHIPPING_INFO}`]
                              .showEditBtn
                          }
                          previewContent={
                            this.state.PANEL_SHIPPING_INFO.showPreview &&
                            this.renderContactAddrInfoPreview("shipping")
                          }
                          closeMdSize={9}
                          toggleOpenPanel={this.toggleOpenPanel}
                          titleStyle={{ fontSize: "19.5px" }}
                          titleWrapperStyle={{ paddingBottom: 14 }}
                          editBtnInBody
                        />
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs>
                        <CheckoutPanel
                          title={"Customer Information"}
                          panel={PANELS.CUSTOMER_INFO}
                          content={this.renderCustomer()}
                          isOpen={isCustomerInfoOpen}
                          template_color={template_color}
                          hasErrors={this.checkPanelErrors(
                            PANELS.CUSTOMER_INFO
                          )}
                          showCheckmark={
                            this.state[`PANEL_${PANELS.CUSTOMER_INFO}`]
                              .showCheckmark
                          }
                          showEditButton={
                            this.state[`PANEL_${PANELS.CUSTOMER_INFO}`]
                              .showEditBtn
                          }
                          previewContent={
                            this.state.PANEL_CUSTOMER_INFO.showPreview &&
                            this.renderCustomerInfoPreview()
                          }
                          toggleOpenPanel={this.toggleOpenPanel}
                        />
                      </Col>
                      {collectShipAddress && (
                        <Col xs>
                          <CheckoutPanel
                            title={ShipingInfoPanelTitle}
                            panel={PANELS.SHIPPING_INFO}
                            content={
                              <Row style={{ paddingBottom: 20 }}>
                                <Col xs>{this.renderShippingAddress(true)}</Col>
                              </Row>
                            }
                            isOpen={isShippingInfoOpen}
                            template_color={template_color}
                            hasErrors={this.checkPanelErrors(
                              PANELS.SHIPPING_INFO
                            )}
                            showEditButton={
                              this.state[`PANEL_${PANELS.SHIPPING_INFO}`]
                                .showEditBtn
                            }
                            showCheckmark={
                              this.state[`PANEL_${PANELS.SHIPPING_INFO}`]
                                .showCheckmark
                            }
                            previewContent={
                              this.state.PANEL_SHIPPING_INFO.showPreview &&
                              this.renderContactAddrInfoPreview("shipping")
                            }
                            closeMdSize={9}
                            toggleOpenPanel={this.toggleOpenPanel}
                            titleStyle={{ fontSize: "19.5px" }}
                            titleWrapperStyle={{ paddingBottom: 14 }}
                            editBtnInBody
                          />
                        </Col>
                      )}
                    </Row>
                  )}
                  {this.showMetadataList() && (
                    <CheckoutPanel
                      title={"Additional Information"}
                      panel={PANELS.ADDITIONAl_INFO}
                      content={this.renderMetadataList()}
                      isOpen={isAdditionalInfoOpen}
                      template_color={template_color}
                      hasErrors={this.checkPanelErrors(PANELS.ADDITIONAl_INFO)}
                      showCheckmark={
                        this.state[`PANEL_${PANELS.ADDITIONAl_INFO}`]
                          .showCheckmark
                      }
                      showEditButton={
                        this.state[`PANEL_${PANELS.ADDITIONAl_INFO}`]
                          .showEditBtn
                      }
                      previewContent={
                        this.state.PANEL_ADDITIONAl_INFO.showPreview &&
                        this.renderAdditionalInfoPreview()
                      }
                      toggleOpenPanel={this.toggleOpenPanel}
                    />
                  )}
                  {this.showComments() && (
                    <CheckoutPanel
                      title={CommentsPanelTitle}
                      panel={PANELS.COMMENTS}
                      content={this.renderComment()}
                      isOpen={isCommentsInfoOpen}
                      template_color={template_color}
                      hasErrors={this.checkPanelErrors(PANELS.COMMENTS)}
                      showCheckmark={
                        this.state[`PANEL_${PANELS.COMMENTS}`].showCheckmark
                      }
                      showEditButton={
                        this.state[`PANEL_${PANELS.COMMENTS}`].showEditBtn
                      }
                      previewContent={
                        this.state.PANEL_COMMENTS.showPreview &&
                        this.renderCommentPreview()
                      }
                      toggleOpenPanel={this.toggleOpenPanel}
                    />
                  )}
                </Col>
                <Col xs md={6} mdStyle={{ paddingLeft: "24px" }}>
                  {this.renderOrderTotal()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Csku>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const cart = getFullCart(state, { buy_inventory: ownProps.buy_inventory });
  const contact = ownProps.contact ?? false;
  let contacts = getContactDropdown(state, {
    parent_id: ownProps.entityId,
  }).filter(
    (c) =>
      [
        ownProps.entityType,
        `${ownProps.entityType}-BILLING`,
        `${ownProps.entityType}-SHIPPING`,
      ].indexOf(c.company_type) !== -1
  );
  let addresses = getAddressDropdown(state, {
    parent_id: ownProps.entityId,
  }).filter((a) => a.parent_type === ownProps.entityType);

  // In marketing shop with a client logged, we only show the address for that contact only
  if (contact) {
    addresses = addresses.filter(
      (a) => a.address_id === contact.contact_default_address_id
    );
    contacts = contacts.filter((c) => c.contact_id === contact.contact_id);
  }

  return {
    cart,
    addresses: addresses,
    contacts: contacts,
    checkout: state.temp.checkout || {},
    getAddress: (address_id) => getAddress(state, { address_id }),
    getContact: (contact_id) => getContact(state, { contact_id }),
    orderTotal: cart.items.reduce((t, i) => t + i.subtotal, 0),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch,
  onCheckout: (
    billing_info,
    shipping_info,
    breakdowns,
    artworks,
    comment,
    payment_data,
    shipping_range_id,
    customer,
    checked_out_amount,
    order_metadata,
    applied_discount,
    customer_po,
    redirect,
    client_id = null,
    onRedirect = null
  ) => {
    dispatch(createSpinnerPopup("Processing...", true));
    return dispatch(
      createCheckout(
        ownProps.entityId,
        billing_info,
        shipping_info,
        breakdowns,
        artworks,
        comment,
        payment_data,
        shipping_range_id,
        customer,
        checked_out_amount,
        order_metadata,
        applied_discount,
        customer_po,
        redirect,
        client_id,
        false,
        onRedirect
      )
    );
  },
  onCreateCardConnectPopup: (
    shop,
    checkoutTotal,
    billing_info,
    shipping_info,
    breakdowns,
    artworks,
    comment,
    shipping_range_id,
    customer,
    order_metadata,
    applied_discount,
    customer_po
  ) => {
    dispatch(
      createCardConnectPopup(
        shop,
        checkoutTotal,
        billing_info,
        shipping_info,
        breakdowns,
        artworks,
        comment,
        shipping_range_id,
        customer,
        order_metadata,
        applied_discount,
        customer_po
      )
    );
  },
  onDeleteCartItem: (item_id) => dispatch(createDeleteCartItem(item_id)),
  onEditCartItem: (item_id) =>
    dispatch(
      createEditCartItem(ownProps.entityId, item_id, ownProps.shop.is_shop_free)
    ),
  onCreateErrorPopup: (error_msg, onClose = null) =>
    dispatch(createErrorAlertPopup(error_msg, onClose)),
  onEditCheckout: (data) =>
    dispatch(createEditCheckout(ownProps.entityId, data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Checkout)
);
