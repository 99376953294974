import React, { useCallback, useRef } from "react";
import {
  ArrowIcon,
  Csku,
  HelpIcon,
  MenuIcon,
  Overlay,
  XIcon,
} from "@commonsku/styles";
import { CartIconLink } from "../helpers";
import { SmallOnly } from "../../ScreenSize";
import { useContainerScroll } from "../../../hooks";
import { ArrowsIcon } from "../../../hooks/useContainerScroll";
import LinkItem from "./LinkItem";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

export default function SmallNav({
  filter,
  menuOpen,
  editing,
  title,
  title_type,
  categories,
  totalQuantity,
  onClickHome,
  onClickAllProducts,
  onClickIntro,
  onChangeFilter,
  handleClickMenu,
  onClickBackButton,
  handleClickTitle,
  showBackButton = false,
  transparentOnTop = false,
  overrideStyles = {},
  linkFontOverrideStyles = {},
  linkOverrideStyles = {},
  secondNavTitle = "Shop",
  linkColor,
  linkUnderline = false,
  isHomeRouteActive = false,
  isShopRouteActive = false,
}) {
  const {
    isShop,
    entityBuyInventory,
  } = usePublicViewEntityContext();
  const {
    templateColor: template_color,
  } = usePublicViewTemplateDataContext();

  const ref = useRef();
  const { canScrollDown, canScrollUp, scrollDown, scrollUp } =
    useContainerScroll(ref);
  const [sidebarClass, setSidebarClass] = React.useState("");

  const onMenuClick = useCallback(
    (e) => {
      handleClickMenu(e);
      setSidebarClass(!menuOpen ? "open" : "");
    },
    [handleClickMenu, menuOpen]
  );

  const MenuClosedButton = showBackButton ? (
    <ArrowIcon
      direction="left"
      size="large"
      color={template_color}
      onClick={onClickBackButton}
    />
  ) : (
    <MenuIcon
      style={{ zIndex: 2, position: "sticky" }}
      size="large"
      color={template_color}
      onClick={onMenuClick}
    />
  );

  const shopTitle = (
    <div
      style={{ width: "100%", padding: 0, paddingLeft: 16, }}
      onClick={handleClickTitle}
    >
      <h1
        style={{
          padding: 0,
          margin: "0 0 16px 0",
          fontSize: "2rem",
          lineHeight: "inherit",
          ...linkFontOverrideStyles,
          ...linkOverrideStyles,
          color: !!entityBuyInventory ? "white" : template_color,
          width: "100%",
          overflowWrap: "break-word",
        }}
      >
        {"text" === title_type ? (
          <>
            {title}
            {!!entityBuyInventory && (
              <p style={{ margin: 0, fontSize: "small" }}>(Buy Inventory)</p>
            )}
          </>
        ) : (
          <img
            src={title}
            alt=""
            style={{ marginBottom: "title" === editing ? 24 : 8 }}
          />
        )}
      </h1>
    </div>
  );

  const helpIcon = menuOpen && (
    <Csku
      forceStyles
      style={{
        width: "100%",
        height: 64,
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fff",
        zIndex: 999,
      }}
      onClick={onClickIntro}
    >
      <HelpIcon
        style={{ marginRight: 0, marginTop: 3 }}
        color={template_color}
        height={"100%"}
        width={"42px"}
      />
    </Csku>
  );

  const scrollArrows =
    menuOpen && ref.current ? (
      <>
        <div
          onClick={scrollUp}
          style={{
            color: "teal",
            fontSize: "3em",
            position: "fixed",
            zIndex: 99999,
            top: 58,
            left: 0,
            width: ref.current.clientWidth,
            transition: "visibility 0s linear 300ms, opacity 300ms",
            visibility: canScrollUp() ? "visible" : "hidden",
            opacity: canScrollUp() ? 1 : 0,
          }}
        >
          <ArrowsIcon
            direction="up"
            color={template_color}
            style={{ margin: 0 }}
          />
        </div>
        <div
          onClick={scrollDown}
          style={{
            color: "teal",
            fontSize: "3em",
            position: "absolute",
            zIndex: 99999,
            bottom: 43,
            left: 0,
            width: ref.current.clientWidth,
            transition: "visibility 0s linear 300ms, opacity 300ms",
            visibility: canScrollDown() ? "visible" : "hidden",
            opacity: canScrollDown() ? 1 : 0,
          }}
        >
          <ArrowsIcon
            direction="down"
            color={template_color}
            style={{ margin: 0 }}
          />
        </div>
      </>
    ) : null;

  const mainMenu = (
    <Csku
      ref={ref}
      forceStyles
      style={{
        height: "calc(100svh - 78px - 64px - env(safe-area-inset-bottom))",
        background: "#fff",
        overflowY: "scroll",
        paddingBottom: 24,
      }}
    >
      {shopTitle}
      <LinkItem
        buy_inventory={entityBuyInventory}
        template_color={linkColor}
        onClick={onClickHome}
        overrideStyle={linkOverrideStyles}
        fontOverrideStyles={linkFontOverrideStyles}
        underline={linkUnderline}
        active={isHomeRouteActive}
      >
        Home
      </LinkItem>
      <LinkItem
        buy_inventory={entityBuyInventory}
        template_color={linkColor}
        onClick={(e) => {
          onClickAllProducts();
          onMenuClick(e);
        }}
        overrideStyle={linkOverrideStyles}
        fontOverrideStyles={linkFontOverrideStyles}
        underline={linkUnderline}
        active={isShopRouteActive}
      >
        {secondNavTitle}
      </LinkItem>
      {categories.map((it) => (
        <LinkItem
          key={"category-nav-item-" + it.item_id}
          buy_inventory={entityBuyInventory}
          template_color={linkColor}
          onClick={(e) => {
            onChangeFilter(it.item_id);
            onMenuClick(e);
          }}
          overrideStyle={linkOverrideStyles}
          fontOverrideStyles={linkFontOverrideStyles}
          underline={linkUnderline}
          active={filter === it.item_id}
        >
          {it.item_name}
        </LinkItem>
      ))}
    </Csku>
  );

  const overLay = menuOpen && (
    <Overlay className="overlay" style={{ top: 78 }} zIndex={-1} />
  );

  return (
    <SmallOnly>
      <div
        style={{
          height: "78px",
          width: "100%",
          padding: "8px 16px 8px 8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          transition: "background 0.25s",
          ...overrideStyles,
          ...(transparentOnTop && !menuOpen
            ? { background: "transparent" }
            : { background: "#fff" }),
        }}
      >
        {menuOpen ? (
          <XIcon
            style={{ width: "32px", height: "32px" }}
            color={template_color}
            onClick={onMenuClick}
          />
        ) : (
          MenuClosedButton
        )}
        {isShop && <CartIconLink
          useV2
          totalQuantity={totalQuantity}
          overrideStyles={{ width: "32px", height: "32px" }}
        />}
      </div>

      <div className={`mobile-sidebar slide-from-left ${sidebarClass}`}>
        {mainMenu}
        {helpIcon}
        {scrollArrows}
        {overLay}
      </div>
    </SmallOnly>
  );
}
